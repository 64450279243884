<template>
    <h1>{{ $t("support.title") }}</h1>
    <div class="platforms">
        <a
            class="platform-windows"
            href="https://dwn.intratone.info/teamviewer/TeamViewerQS.exe"
        >
            <i class="fab fa-windows"></i>
            <h5>{{ $t("brands.windows") }}</h5>
        </a>
        <a
            class="platform-apple"
            href="https://dwn.intratone.info/teamviewer/TeamViewerQS.dmg"
        >
            <i class="fab fa-apple"></i>
            <h5>{{ $t("brands.apple") }}</h5>
        </a>
    </div>
    <div class="contact-informations">
        {{ $t("text.contactEmail") }}
        <a :href="'mailto:' + chooseSupport()" class="mail-link">
            {{ supportMail }}
        </a>
        <br />
        {{ $t("text.contactAddress") }}
        <br />
        {{ $t("text.contactCity") }}
        <br />
        {{ $t("text.contactPhone") }}
    </div>
    <p class="version">
        {{ version }}
    </p>
    <basic-button class="back-button" link="login">
        {{ $t("navigation.back") }}
    </basic-button>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"

export default {
    components: { BasicButton },
    name: "Support",
    commponents: {
        BasicButton,
    },
    data() {
        return {
            version: this.$store.getters["account/getVersion"],
            supportMail: null,
        }
    },
    methods: {
        chooseSupport() {
            switch (this.$store.getters["account/getLanguage"]) {
                case "be-fr":
                case "ch-fr":
                case "lu-fr":
                case "es":
                    this.supportMail = "support@intratone.fr"
                    break
                case "de":
                case "lu-de":
                case "ch-de":
                    this.supportMail = "support@intratone.de"
                    break
                case "en":
                case "dk":
                case "fi":
                case "no":
                case "se":
                    this.supportMail = "support@intratone.uk.com"
                    break
                case "nl":
                case "be-fl":
                    this.supportMail = "support@intratone.nl"
                    break
                case "fr":
                default:
                    this.supportMail = "technique@intratone.fr"
                    break
            }
            return this.supportMail
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

h1 {
    font-family: "Avenir Black";
    font-size: $veryBig;
    padding: 0 50px 30px 50px;
    margin: 50px auto 0 auto;
    width: 60%;
}

.platforms {
    display: flex;
    margin: 40px auto 0 auto;
    width: 45%;
    // background-color: orange;

    .platform-windows {
        width: 50%;
        color: $blue-lighter;
        transition: color 150ms;
        text-underline-offset: 5px;
        margin-top: 10px;

        i {
            font-size: $immense;
        }

        h5 {
            margin-top: 20px;
        }
    }
    .platform-windows:hover {
        color: $orange-neutral;
        cursor: pointer;
    }
    .platform-apple {
        width: 50%;
        color: $blue-lighter;
        transition: color 150ms;
        text-underline-offset: 5px;

        i {
            font-size: $immense;
        }

        h5 {
            margin-top: 20px;
        }
    }
    .platform-apple:hover {
        color: $orange-neutral;
        cursor: pointer;
    }
}

.contact-informations {
    margin-top: 50px;

    .mail-link {
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
        color: $blue-neutral;
        transition: color 150ms;
    }
    .mail-link:hover {
        color: $orange-neutral;
    }
}

.back-button {
    width: 150px !important;
}

.version {
    padding-top: 20px;
}
</style>
